import { Col, Form, FormInstance, Input, Row, Select } from 'antd'
import { FC, useContext } from 'react'
import CDatePicker from '../../../../components/Common/CDatePicker'
import CustomDropdown from '../../../../components/Common/CustomDropdown/CustomDropdown'
import { ModalName } from '../../modal/customModalSlice'

import CSwitch from '../../../../components/Common/CSwitch/CSwitch'
import { t } from 'i18next'
import { ResearchContext } from './ResearchBar'
import dayjs from 'dayjs'

export type FilterType =
  | 'BL'
  | 'Libelle'
  | 'Fournisseur'
  | 'Chrono'
  | 'Debut'
  | 'Fin'
  | 'Reference'
  | 'Status'
  | 'Transporteur'
  | 'Palette'
  | 'Lot'
  | 'Cdn'
  | 'Zone'
  | 'Allee'
  | 'Travee'
  | 'Niveau'
  | 'Type Cdn'
  | 'Destinataire'
  | 'client'
  | 'company'
  | 'warehouse'
  | 'Toutes références'
  | 'Tous membres'
  | 'De:'
  | 'A:'
  | 'AttenduType'
  | 'AttenduMouvement'
  | 'AttenduEtat'
  | 'StockType'
  | 'StockMouvement'
  | 'StockEtat'
  | 'EmplacementVide'
  | 'CommandeType'
  | 'CommandeMouvement'
  | 'CommandeEtat'
  | 'AttenduPalette'
  | 'Evènement'
  | 'Type'

function renderField(name: FilterType) {
  const AttenduTypes = [
    { value: '10', label: t('attenduType10') },
    { value: '11', label: t('attenduType11') },
    { value: '12', label: t('attenduType12') },
    { value: '13', label: t('attenduType13') },
    { value: '14', label: t('attenduType14') },
    { value: '15', label: t('attenduType15') },
    { value: '16', label: t('attenduType16') },
  ]

  const AttenduMouvements = [
    { value: 'EX', label: t('mouvement.EX') },
    // { value: 'EN', label: t('mouvement.EN') },
  ]

  const StatusOptions = [
    { value: '0', label: t('status0') },
    { value: 'LB', label: t('statusLB') },
  ]

  const AttenduEtatOptions = [
    { value: '0', label: t('attenduEtat0') },
    { value: '2', label: t('attenduEtat2') },
    { value: '3', label: t('attenduEtat3') },
    { value: '4', label: t('attenduEtat4') },
    { value: '5', label: t('attenduEtat5') },
    { value: '1', label: t('attenduEtat1') },
  ]

  const StockTypes = [
    { value: '71', label: t('stockType71') },
    // { value: '91', label: t('stockType91') },
    { value: '74', label: t('stockType74') },
    { value: '24', label: t('stockType24') },
    // { value: '2+', label: t('stockType2+') },
    { value: '79', label: t('stockType79') },
    { value: '2K', label: t('stockType2K') },
    { value: '23', label: t('stockType23') },
    { value: '75', label: t('stockType75') },
    { value: '22', label: t('stockType22') },
    { value: '7K', label: t('stockType7K') },
    { value: '25', label: t('stockType25') },
  ]

  const CommandeTypes = [
    { value: '60', label: t('commandeType60') },
    { value: '61', label: t('commandeType61') },
    { value: '62', label: t('commandeType62') },
    { value: '64', label: t('commandeType64') },
    { value: '65', label: t('commandeType65') },
    { value: '66', label: t('commandeType66') },
    { value: '79', label: t('commandeType79') },
    { value: '7K', label: t('commandeType7K') },
  ]

  const StockMouvements = [
    { value: 'EN', label: t('stockMouvementEN') },
    { value: 'MV', label: t('stockMouvementMV') },
    // { value: 'ER', label: t('stockMouvementER') },
    { value: 'SR', label: t('stockMouvementSR') },
    { value: 'SO', label: t('stockMouvementSO') },
  ]

  const StockEtatOptions = [
    { value: '0', label: t('stockEtat0') },
    { value: '1', label: t('stockEtat1') },
    { value: '2', label: t('stockEtat2') },
    { value: '3', label: t('stockEtat3') },
  ]

  const cdnOptions = [
    { value: '2', label: 'Niveau 2' },
    { value: '3', label: 'Niveau 3' },
  ]

  const CommandeMouvements = [{ value: 'SO', label: t('commandeMouvementSO') }]

  const CommandeEtatOptions = [
    { value: '0', label: t('commandeEtat0') },
    { value: '1', label: t('commandeEtat1') },
    { value: '2', label: t('commandeEtat2') },
    // { value: '3', label: t('commandeEtat3') },
    // { value: '4', label: t('commandeEtat4') },
    // { value: '5', label: t('commandeEtat5') },
    // { value: '6', label: t('commandeEtat6') },
    // { value: '7', label: t('commandeEtat7') },
    // { value: '8', label: t('commandeEtat8') },
    // { value: '9', label: t('commandeEtat9') },
    // { value: '10', label: t('commandeEtat10') },
    { value: '11', label: t('commandeEtat11') },
  ]

  const cdnTypeOptions = [
    { value: 0, label: t('typeCDN.standard') },
    { value: 1, label: t('typeCDN.variable') },
  ]
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { levels, startDate, endDate } = useContext(ResearchContext)
  switch (name) {
    case 'BL':
      return (
        <Form.Item
          label="B.L"
          name="bl"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <Input />
        </Form.Item>
      )
    case 'Transporteur':
      return (
        <Form.Item
          label="Transporteur"
          name="transporter"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <Input />
        </Form.Item>
      )
    case 'Reference':
      return (
        <Form.Item
          label="Référence"
          name="product"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <Input />
        </Form.Item>
      )

    case 'Zone':
      return (
        <Form.Item
          label="Zone"
          name="zone"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <Input />
        </Form.Item>
      )
    case 'Palette':
      return (
        <Form.Item
          label="Palette"
          name="sscc"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <Input />
        </Form.Item>
      )
    case 'AttenduPalette':
      return (
        <Form.Item
          label="Palette"
          name="palette"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <Input />
        </Form.Item>
      )
    case 'Cdn':
      return (
        <Form.Item
          label="Cdn."
          name="cdn"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CustomDropdown
            width="w-full"
            type="default"
            options={cdnOptions}
            placeholder={t('all')}
            passValueOnly
            showAllOption
          />
        </Form.Item>
      )
    case 'Chrono':
      return (
        <Form.Item
          label="Chrono"
          name="chrono"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <Input maxLength={55} />
        </Form.Item>
      )
    case 'Libelle':
      return (
        <Form.Item
          label="Libellé"
          name="description"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <Input maxLength={55} />
        </Form.Item>
      )
    case 'Fournisseur':
      return (
        <Form.Item
          label="Fournisseur"
          name="supplier"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <Input maxLength={55} />
        </Form.Item>
      )
    case 'Status':
      return (
        <Form.Item
          label="Statut"
          name="status"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CustomDropdown
            width="w-full"
            type="default"
            options={StatusOptions}
            placeholder={t('all')}
            passValueOnly
            showAllOption
          />
        </Form.Item>
      )
    case 'Allee':
      return (
        <Form.Item
          label="Allée"
          name="aisle"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <Input />
        </Form.Item>
      )
    case 'Lot':
      return (
        <Form.Item
          label="Lot"
          name="lot"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <Input />
        </Form.Item>
      )
    case 'Type Cdn':
      return (
        <Form.Item
          label="Type Cdn."
          name="type_cdn"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CustomDropdown
            width="w-full"
            type="default"
            options={cdnTypeOptions}
            placeholder={t('all')}
            passValueOnly
            showAllOption
          />
        </Form.Item>
      )
    case 'AttenduType':
      return (
        <Form.Item
          label="Type"
          name="type"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CustomDropdown
            width="w-full"
            type="default"
            options={AttenduTypes}
            placeholder={t('all')}
            passValueOnly
            showAllOption
          />
        </Form.Item>
      )
    case 'StockType':
      return (
        <Form.Item
          label="Type"
          name="type"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CustomDropdown
            width="w-full"
            style={{ overflowY: 'scroll' }}
            type="default"
            options={StockTypes}
            dropdownWidth={400}
            placeholder={t('all')}
            passValueOnly
            showAllOption
          />
        </Form.Item>
      )
    case 'CommandeType':
      return (
        <Form.Item
          label="Type"
          name="type"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CustomDropdown
            width="w-full"
            style={{ overflowY: 'scroll' }}
            type="default"
            options={CommandeTypes}
            placeholder={t('all')}
            passValueOnly
            showAllOption
          />
        </Form.Item>
      )
    case 'Destinataire':
      return (
        <Form.Item
          label="Destinataire"
          name="addressee"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <Input />
        </Form.Item>
      )
    case 'Debut':
      return (
        <Form.Item
          label="Début"
          name="start_date"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CDatePicker
            placement="topLeft"
            className="w-full"
            disabledDate={(cur) => {
              return cur && endDate ? cur > dayjs(endDate) : false
            }}
          />
        </Form.Item>
      )
    case 'Fin':
      return (
        <Form.Item
          label="Fin"
          name="end_date"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CDatePicker
            placement="topLeft"
            className="w-full"
            disabledDate={(cur) => {
              return cur && startDate ? cur < dayjs(startDate) : false
            }}
          />
        </Form.Item>
      )
    case 'Travee':
      return (
        <Form.Item
          label="Travée"
          name="bay"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <Input />
        </Form.Item>
      )
    case 'Niveau':
      return (
        <Form.Item
          label="Niveau"
          name="level"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CustomDropdown
            width="w-full"
            type="default"
            options={levels?.map((i: number) => {
              return {
                label: i,
                value: i,
              }
            })}
            placeholder={t('all')}
            passValueOnly
            showAllOption
          />
        </Form.Item>
      )
    case 'AttenduEtat':
      return (
        <Form.Item
          label="Etat"
          name="eta"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CustomDropdown
            width="w-full"
            type="default"
            options={AttenduEtatOptions}
            placeholder={t('all')}
            passValueOnly
            showAllOption
          />
        </Form.Item>
      )
    case 'StockEtat':
      return (
        <Form.Item
          label="Etat"
          name="eta"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CustomDropdown
            width="w-full"
            type="default"
            options={StockEtatOptions}
            placeholder={t('all')}
            passValueOnly
            showAllOption
          />
        </Form.Item>
      )
    case 'CommandeEtat':
      return (
        <Form.Item
          label="Etat"
          name="eta"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CustomDropdown
            width="w-full"
            type="default"
            options={CommandeEtatOptions}
            placeholder={t('all')}
            passValueOnly
            showAllOption
          />
        </Form.Item>
      )
    case 'AttenduMouvement':
      return (
        <Form.Item
          label="Mouvement"
          name="mouvement"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CustomDropdown
            width="w-full"
            type="default"
            options={AttenduMouvements}
            placeholder={t('all')}
            passValueOnly
            showAllOption
          />
        </Form.Item>
      )
    case 'StockMouvement':
      return (
        <Form.Item
          label="Mouvement"
          name="mouvement"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CustomDropdown
            width="w-full"
            type="default"
            options={StockMouvements}
            passValueOnly
            placeholder={t('all')}
            showAllOption
          />
        </Form.Item>
      )
    case 'CommandeMouvement':
      return (
        <Form.Item
          label="Mouvement"
          name="mouvement"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CustomDropdown
            width="w-full"
            type="default"
            options={CommandeMouvements}
            placeholder={t('all')}
            passValueOnly
            showAllOption
          />
        </Form.Item>
      )
    case 'EmplacementVide':
      return (
        <Form.Item
          label="Empl. libre"
          name="emplacementVibe"
          labelAlign="left"
          colon={false}
          wrapperCol={{ span: 12 }}
          labelCol={{ span: 12 }}
        >
          <CSwitch />
        </Form.Item>
      )

    case 'Evènement':
      return (
        <Form.Item noStyle label="" name="actions" colon={false}>
          <Select
            allowClear
            mode="multiple"
            maxTagCount="responsive"
            labelInValue
            filterOption={false}
            onSearch={() => {}}
            notFoundContent={<></>}
            options={[
              {
                label: 'Création',
                value: 'create',
              },
              {
                label: 'Edition',
                value: 'edit',
              },
              {
                label: 'Suppression',
                value: 'delete',
              },
              {
                label: 'Entrée',
                value: 'entry',
              },
              {
                label: 'Sortie',
                value: 'exit',
              },
            ]}
            placeholder="Evènement"
            style={{ width: 220 }}
          />
        </Form.Item>
      )

    case 'Type':
      return (
        <Form.Item noStyle label="" name="types" colon={false}>
          <Select
            showSearch={false}
            mode="multiple"
            maxTagCount="responsive"
            labelInValue
            filterOption={false}
            notFoundContent={<></>}
            allowClear
            options={[
              { label: '10: Attendu de réception manuel', value: '10' },
              { label: '11: Attendu de réception (import)', value: '11' },
              { label: '12: Attendu de réception (EDI)', value: '12' },
              { label: '13: Attendu de réception (PO)', value: '13' },
              { label: '14: Attendu de réception (CoPack)', value: '14' },
              { label: '15: Attendu de réception (FTP)', value: '15' },
              { label: '60: Commande de sortie manuel', value: '60' },
              { label: '61: Commande de sortie(import)', value: '61' },
              { label: '62: Commande de sortie(EDI)', value: '62' },
              { label: '64: Commande de sortie(CoPack)', value: '64' },
              { label: '65: Commande de sorie(FTP)', value: '65' },
              { label: '66: Commande de sortie(Mail)', value: '66' },
              { label: '91: Sortie de support', value: '91' },
              { label: '2+: Entrée par régularisation', value: '2+' },
              { label: '71: Sortie par régularisation', value: '71' },
              { label: '74: Sortie par mouvement manuel', value: '74' },
              { label: '24: Entrée par mouvement manuel', value: '24' },
              { label: '79: Préparation - Sortie par commande', value: '79' },
              {
                label: `23: Entrée par recherche d'emplacement auto.`,
                value: '23',
              },
              { label: '75: Sortie par mouvement auto.', value: '75' },
              { label: `22: Entrée par recherche d'empl manuel`, value: '22' },
              { label: '7K: Sortie par réappro-picking', value: '7K' },
              { label: '25: Entrée par mouvement auto', value: '25' },
            ]}
            placeholder="Type"
            style={{ width: 400 }}
          />
        </Form.Item>
      )
    default:
      return <div />
  }
}

const ResearchBarExpand: FC<{
  listFilters: FilterType[]
  screen?: ModalName
  useForHistory?: boolean
}> = ({ listFilters, screen, useForHistory = false }) => {
  const listFilterByScreen = listFilters.filter((el) =>
    screen === ModalName.STOCK
      ? el !== 'Reference'
      : screen === ModalName.ATTENDU
      ? el !== 'BL'
      : el
  )

  if (!useForHistory)
    return (
      <div className="block mx-5 p-4 pt-0 bg-[#EEEEEE] rounded-b-2xl">
        <div className="w-full h-[1px] bg-gray1" />
        <div className="grid grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 pr-4 gap-x-6 pt-4">
          {listFilterByScreen.map((name, index) => (
            <div key={index} className="w-full">
              {renderField(name)}
            </div>
          ))}
        </div>
      </div>
    )

  return (
    <div className="block mx-5 p-4 pt-0 bg-[#EEEEEE] rounded-b-2xl">
      <div className="w-full h-[1px] bg-gray1" />
      <Row className="pt-4" gutter={24}>
        {listFilterByScreen.map((name, index) => (
          <Col key={index} className="">
            {renderField(name)}
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default ResearchBarExpand
