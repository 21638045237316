export function isPopUpDisplay() {
  const modal = document.getElementsByClassName('ant-modal')
  const detailModal = document.getElementsByClassName('modal-new-stock')
  return modal?.length > 0 || detailModal?.length > 0
}

export function shouldRefreshData() {
  const screenNeedRefresh = ['commande', 'attendu', 'stock']
  const path = window.location.pathname
  for (let i = 0; i < screenNeedRefresh.length; i++) {
    if (path === `/gestion-de-stock/${screenNeedRefresh[i]}`) {
      return !isPopUpDisplay()
    }
  }
  return false
}
