import { DatePicker, Form, Input, notification, Select } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { cloneDeep } from 'lodash'
import {
  Context,
  createContext,
  FC,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import ARROW_DOWN from '../../../../assets/icons/arrow-down.svg'
import ARROW_UP from '../../../../assets/icons/arrow-up.svg'
import CLOSE_ICON from '../../../../assets/icons/close.svg'
import SEARCH_ICON from '../../../../assets/icons/search.svg'
import CustomDropdown from '../../../../components/Common/CustomDropdown/CustomDropdown'
import Define from '../../../../constants/define'
import clientWarehouseApi from '../../../../http/clientWarehouseApi'
import historyApi from '../../../../http/historyApi'
import {
  chooseClient,
  clearClient,
  getClients,
  getCompanies,
  getWarehouses,
} from '../../../../redux/reducers/accountSlice'
import { getUrlFilterParams, isValidSecond } from '../../../../utils'
import {
  disabledDateAfter,
  disabledDateBefore,
  MinDayForSearch,
} from '../../../history/func'
import { ModalName } from '../../modal/customModalSlice'
import './ResearchBar.scss'
import ResearchBarExpand, { FilterType } from './ResearchBarExtend'
import { shouldRefreshData } from '../../../../utils/modal'

enum Source {
  FROM = 'FROM',
  TO = 'TO',
}

type ResearchType = { levels?: number[]; startDate?: Date; endDate?: Date }
export const ResearchContext: Context<ResearchType> = createContext({})

const reducer = (state: ResearchType, updatedProperty: ResearchType) => ({
  ...state,
  ...updatedProperty,
})

const ResearchBar: FC<{
  defaultDisplayFilters?: FilterType[]
  listFilters?: FilterType[]
  requiredToAdd?: FilterType[]
  requiredToFilters?: FilterType[]
  onSearch?: (values: any) => void
  screen?: ModalName
  isCollapsible: boolean
  setIsCollapsible: (val: boolean) => void
  searchBarHeight?: number
  totalIn?: number
  total?: number
  useForHistory?: boolean
}> = ({
  defaultDisplayFilters,
  listFilters,
  requiredToAdd = [],
  onSearch,
  requiredToFilters,
  screen,
  isCollapsible,
  setIsCollapsible,
  searchBarHeight,
  totalIn,
  total,
  useForHistory = false,
}) => {
  const [form] = Form.useForm()
  const [isOnlyOneOption, setOnlyOneOption] = useState(false)
  const [searchValue, setSearchValue] = useReducer(reducer, { levels: [] })

  const filterKey = useSelector((state: RootState) => state.selector).data
    .searchBar
  const [utilisateurOptions, setUtilisateurOptions] = useState<
    { label: string; value: string }[]
  >([])

  const {
    loadingClient,
    loadingCompany,
    loadingWarehouse,
    choosingClient,
    choosingCompany,
    choosingWarehouse,
    clientList,
    companyList,
    warehouseList,
  } = useSelector((state: RootState) => state.account)
  const dispatch = useDispatch()

  const handleCollapsible = () => {
    setIsCollapsible(!isCollapsible)
  }

  const relocateScrollVertical = (isOpenFilter?: boolean) => {
    if (searchBarHeight) {
      const parentElement = document.getElementsByClassName('attendu-cmd-stock')
      if (parentElement && parentElement[0]) {
        let element = parentElement[0].getElementsByClassName(
          'rc-virtual-list-scrollbar-vertical'
        )
        if (element && element[0]) {
          // @ts-ignore
          element[0].style.setProperty(
            'top',
            isOpenFilter ? `${14 + (searchBarHeight || 0)}rem` : '14rem',
            'important'
          )
        }
      }
    }
  }

  useEffect(() => {
    // relocate scrollbar in table of list screen when filter is open or close
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const researchBarExtendHeight = document.getElementsByClassName(
          'research-bar-expand'
        )?.[0]
        relocateScrollVertical(!!researchBarExtendHeight)
      }
    })
    const parentElement = document.getElementsByClassName('attendu-cmd-stock')
    if (parentElement && parentElement[0]) {
      observer.observe(parentElement[0])
    }
  }, [])

  function getNoneFilterData() {
    // fetch data when there is no dropdown chose
    const filters = {
      user_id: localStorage.getItem(Define.USER_ID) || '',
      user_role: localStorage.getItem(Define.ROLE) || '',
    }
    dispatch(getClients(filters))
    dispatch(getCompanies(filters))
    dispatch(getWarehouses(filters))
  }

  function researchByThreeCondition(
    filters: {
      user_role: string
      user_id: string
    },
    localCompany?: string,
    localWarehouse?: string,
    localCompanyNom?: string,
    localWarehouseNom?: string,
    localClient?: string,
    localClientCodenom?: string
  ) {
    dispatch(
      getClients({
        ...filters,
        company_id: localCompany,
        warehouse_id: localWarehouse,
        company_code_nom: localCompanyNom,
        warehouse_code_nom: localWarehouseNom,
      })
    )
    dispatch(
      getCompanies({
        ...filters,
        client_id: localClient,
        warehouse_id: localWarehouse,
        client_code_nom: localClientCodenom,
        warehouse_code_nom: localWarehouseNom,
      })
    )
    dispatch(
      getWarehouses({
        ...filters,
        client_id: localClient,
        company_id: localCompany,
        client_code_nom: localClientCodenom,
        company_code_nom: localCompanyNom,
      })
    )
    dispatch(
      chooseClient({
        client: localClient,
        company: localCompany,
        warehouse: localWarehouse,
      })
    )
    setTimeout(() => {
      form.setFieldsValue({
        client: localClient,
        company: localCompany,
        warehouse: localWarehouse,
      })

      if (useForHistory) {
        const payload = {
          client_code_nom: localClientCodenom,
          company_code_nom: localCompanyNom,
          warehouse_code_nom: localWarehouseNom,
        }
        // @ts-ignore
        historyApi.getUtilisateur(payload).then((res) => {
          setUtilisateurOptions(
            (res || [])
              .sort((a, b) => {
                return a.name.localeCompare(b.name, undefined, {
                  numeric: true,
                  sensitivity: 'base',
                })
              })
              .map((item) => {
                return {
                  label: item.name,
                  value: item.id,
                }
              })
          )
        })
      }
    }, 500)
  }

  useEffect(() => {
    async function getData() {
      // fetch data when first time mount
      const searchParams = new URLSearchParams(window.location.search)
      const clientInUrl = searchParams.get('client')
      let warehouseInUrl = searchParams.get('warehouse')
      if (warehouseInUrl && !warehouseInUrl?.includes('ware_')) {
        warehouseInUrl = `ware_${warehouseInUrl}`
      }
      let companyInUrl = searchParams.get('company')
      let localClient = localStorage.getItem(Define.CHOOSING_CLIENT) || ''
      let localClientCodenom =
        localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''
      let localCompany = localStorage.getItem(Define.CHOOSING_COMPANY) || ''
      let localWarehouse = localStorage.getItem(Define.CHOOSING_WAREHOUSE) || ''
      let localCompanyNom =
        localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || ''
      let localWarehouseNom =
        localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || ''
      const filters = {
        user_id: localStorage.getItem(Define.USER_ID) || '',
        user_role: localStorage.getItem(Define.ROLE) || '',
      }
      if (companyInUrl) {
        // In case there is param company in url
        if (
          // If params in url is different from data saved in local storage, fetch data and set it as default
          companyInUrl !== localCompany ||
          warehouseInUrl !== localWarehouse ||
          clientInUrl !== localClient
        ) {
          const companies = await clientWarehouseApi.getFilterCompany(filters)
          const choosingCompany = companies.data.entry.find(
            (item) => item.id === companyInUrl
          )
          localStorage.setItem(Define.CHOOSING_CLIENT, '')
          localStorage.setItem(Define.CHOOSING_COMPANY, companyInUrl)
          localStorage.setItem(Define.CHOOSING_WAREHOUSE, '')
          localStorage.setItem(Define.CHOOSING_CLIENT_CODENOM, '')
          localStorage.setItem(
            Define.CHOOSING_COMPANY_CODENOM,
            choosingCompany?.code_nom || ''
          )
          localStorage.setItem(
            Define.CHOOSING_WAREHOUSE_CODENOM,
            choosingCompany?.code_nom || ''
          )
          researchByThreeCondition(
            filters,
            companyInUrl,
            undefined,
            choosingCompany?.code_nom,
            undefined,
            undefined,
            undefined
          )
        } else {
          // If params in url are same as localstorage , then treat it as default
          researchByThreeCondition(
            filters,
            localCompany,
            localWarehouse,
            localCompanyNom,
            localWarehouseNom,
            localClient,
            localClientCodenom
          )
        }
      } else {
        if (!localClient || !localCompany || !localWarehouse) {
          // search all if there are no default search in localstorage
          getNoneFilterData()
        } else {
          // if there are previous search condition in cache, search by them
          researchByThreeCondition(
            filters,
            localCompany,
            localWarehouse,
            localCompanyNom,
            localWarehouseNom,
            localClient,
            localClientCodenom
          )
        }
      }
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //get all data real time
  useEffect(() => {
    const intervalData = setInterval(() => {
      if (shouldRefreshData()) {
        const localClient = localStorage.getItem(Define.CHOOSING_CLIENT) || ''
        const localClientCodenom =
          localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''
        const localCompany = localStorage.getItem(Define.CHOOSING_COMPANY) || ''
        const localWarehouse =
          localStorage.getItem(Define.CHOOSING_WAREHOUSE) || ''
        const localCompanyNom =
          localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || ''
        const localWarehouseNom =
          localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || ''
        if (!localClient || !localCompany || !localWarehouse) {
          // search all if there are no default search in localstorage
          getNoneFilterData()
          setTimeout(() => {
            form.setFieldsValue({
              client: undefined,
              company: undefined,
              warehouse: undefined,
            })
          }, 500)
        } else {
          // if there are previous search condition in cache, search by them
          const filters = {
            user_id: localStorage.getItem(Define.USER_ID) || '',
            user_role: localStorage.getItem(Define.ROLE) || '',
          }
          researchByThreeCondition(
            filters,
            localCompany,
            localWarehouse,
            localCompanyNom,
            localWarehouseNom,
            localClient,
            localClientCodenom
          )
        }
      }
    }, Define.ONE_MINUTE)
    return () => {
      clearInterval(intervalData)
    }
  }, [])

  useEffect(() => {
    if (!choosingClient && !choosingCompany && !choosingWarehouse) {
      // clear choosing option if there is no option in dropdowns
      form.resetFields()
    }
  }, [choosingClient, choosingCompany, choosingWarehouse, form])

  const clientOptions =
    clientList?.map((item) => {
      return { label: item.code_nom, value: item.id }
    }) || []

  const companyOptions =
    companyList?.map((item) => {
      return { label: item.code_nom, value: item.id }
    }) || []

  const warehouseOptions =
    warehouseList?.map((item) => {
      return { label: item.code_nom, value: item.id }
    }) || []

  useEffect(() => {
    async function checkFirstData() {
      // get all filter option when mount to calculate the number of options
      const filters = {
        user_id: localStorage.getItem(Define.USER_ID) || '',
        user_role: localStorage.getItem(Define.ROLE) || '',
      }
      const [fullClients, fullCompanies, fullWarehouses] = await Promise.all([
        clientWarehouseApi.getFilterClient(filters),
        clientWarehouseApi.getFilterCompany(filters),
        clientWarehouseApi.getFilterWarehouse(filters),
      ])
      const clientList = fullClients.data.entry
      const companyList = fullCompanies.data.entry
      const warehouseList = fullWarehouses.data.entry
      let localClient = localStorage.getItem(Define.CHOOSING_CLIENT) || ''
      let localCompany = localStorage.getItem(Define.CHOOSING_COMPANY) || ''
      let localWarehouse = localStorage.getItem(Define.CHOOSING_WAREHOUSE) || ''

      if (
        !clientList.find((item) => item.id === localClient) ||
        !companyList.find((item) => item.id === localCompany) ||
        !warehouseList.find((item) => item.id === localWarehouse)
      ) {
        resetFilter()
      }
      if (
        clientList?.length === 1 &&
        companyList?.length === 1 &&
        warehouseList?.length === 1
      ) {
        // case there is only one option in each dropdown, set them as default
        setOnlyOneOption(true)
        form.setFieldsValue({
          client: clientList[0].id,
          company: companyList[0].id,
          warehouse: warehouseList[0].id,
        })
        localStorage.setItem(Define.CHOOSING_CLIENT, clientList[0].id || '')
        localStorage.setItem(Define.CHOOSING_COMPANY, companyList[0].id || '')
        localStorage.setItem(
          Define.CHOOSING_WAREHOUSE,
          warehouseList[0].id || ''
        )
        localStorage.setItem(
          Define.CHOOSING_CLIENT_CODENOM,
          clientList[0].code_nom || ''
        )
        localStorage.setItem(
          Define.CHOOSING_COMPANY_CODENOM,
          companyList[0].code_nom || ''
        )
        localStorage.setItem(
          Define.CHOOSING_WAREHOUSE_CODENOM,
          warehouseList[0].code_nom || ''
        )
        dispatch(
          chooseClient({
            client: clientList[0].id,
            company: companyList[0].id,
            warehouse: warehouseList[0].id,
          })
        )
        setTimeout(() => {
          const condition = Object.fromEntries(
            new URLSearchParams(window.location.search)
          )
          onSearch?.(condition)
        }, 100)
      }
    }

    checkFirstData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitForm = () => {
    form.submit()
  }

  const onFinish = (values: any) => {
    let isValid = true
    // check if required fields are chosen
    // eslint-disable-next-line array-callback-return
    requiredToAdd?.some((field) => {
      if (!values[field]) {
        isValid = false
        return true
      }
    })
    if (!isValid) {
      return notification.warning({
        message: t('filterNotValid'),
        placement: 'topRight',
        duration: 5,
      })
    }

    const client_id =
      values.client?.value ||
      clientOptions.find((item) => item.value === values.client)?.value
    const client_code_nom =
      values.client?.label ||
      clientOptions.find((item) => item.value === values.client)?.label
    const company_id =
      values.company?.value ||
      companyOptions.find((item) => item.value === values.company)?.value
    const company_code_nom =
      values.company?.label ||
      companyOptions.find((item) => item.value === values.company)?.label
    const warehouse_id =
      values.warehouse?.value ||
      warehouseOptions.find((item) => item.value === values.warehouse)?.value
    const warehouse_code_nom =
      values.warehouse?.label ||
      warehouseOptions.find((item) => item.value === values.warehouse)?.label

    if (useForHistory) {
      if (client_code_nom && (!company_id || !warehouse_id)) {
        const object_search_types = values['object_search_types']
        if (!object_search_types) {
          return notification.warning({
            message: t('filterNotValid'),
            placement: 'topRight',
            duration: 5,
          })
        }

        if (
          !(object_search_types || [])
            .map((item: { value: any }) => item.value)
            .includes('reference')
        ) {
          return notification.warning({
            message: t('filterNotValid'),
            placement: 'topRight',
            duration: 5,
          })
        }
      }
    }
    dispatch(
      chooseClient({
        client: client_id,
        company: company_id,
        warehouse: warehouse_id,
      })
    )
    // save to localstorage to reuse when refresh or relogin
    localStorage.setItem(Define.CHOOSING_CLIENT, client_id || '')
    localStorage.setItem(Define.CHOOSING_COMPANY, company_id || '')
    localStorage.setItem(Define.CHOOSING_WAREHOUSE, warehouse_id || '')
    localStorage.setItem(Define.CHOOSING_CLIENT_CODENOM, client_code_nom || '')
    localStorage.setItem(
      Define.CHOOSING_COMPANY_CODENOM,
      company_code_nom || ''
    )
    localStorage.setItem(
      Define.CHOOSING_WAREHOUSE_CODENOM,
      warehouse_code_nom || ''
    )
    setTimeout(() => {
      delete values.client
      delete values.warehouse
      delete values.company
      const filteredObj = Object.fromEntries(
        Object.entries(values)
          .filter(
            ([_, value]) =>
              value !== undefined && value !== '' && value !== null
          )
          // convert data's type which is datetime to seconds
          .map(([name, value]) => {
            if (typeof value === 'object' || isValidSecond(value as string)) {
              if (name === 'start_date') {
                // @ts-ignore
                const timeValue = dayjs(value).startOf('day').valueOf()
                value = Math.round(
                  timeValue > 100000000000 ? timeValue / 1000 : timeValue
                )
              }

              if (name === 'end_date') {
                // @ts-ignore
                const timeValue = dayjs(value).endOf('day').valueOf()
                value = Math.round(
                  timeValue > 100000000000 ? timeValue / 1000 : timeValue
                )
              }
            }
            return [name, typeof value === 'string' ? value.trim() : value]
          })
      )
      onSearch?.(filteredObj)
    }, 100)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  function resetFilter() {
    dispatch(clearClient())
    getNoneFilterData()
    localStorage.setItem(Define.CHOOSING_CLIENT, '')
    localStorage.setItem(Define.CHOOSING_COMPANY, '')
    localStorage.setItem(Define.CHOOSING_WAREHOUSE, '')
    localStorage.setItem(Define.CHOOSING_CLIENT_CODENOM, '')
    localStorage.setItem(Define.CHOOSING_COMPANY_CODENOM, '')
    localStorage.setItem(Define.CHOOSING_WAREHOUSE_CODENOM, '')
    setSearchValue({})
    setTimeout(() => {
      onSearch?.({})
      setTimeout(() => {
        form.resetFields()
      }, 200)
    }, 200)
  }

  // fetch max level of each client, warehouse, company to apply to level's filter
  function searchLevel(allValues: any) {
    clientWarehouseApi
      .getLevel(
        allValues.client?.label ||
          localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM),
        allValues.warehouse?.label ||
          localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM),
        allValues.company?.label ||
          localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM)
      )
      .then((res) => {
        const result = res?.data?.entry as string[]
        let levelLists = []
        if (result?.includes('-1')) {
          levelLists.push(0)
        }
        const max = result?.reduce((a, b) => Math.max(a, Number(b)), 0) || 0
        levelLists = [
          ...levelLists,
          ...Array.from({ length: max || 0 }, (_, i) => i + 1),
        ]
        setSearchValue({ levels: levelLists })
      })
  }

  useEffect(() => {
    searchLevel({})
  }, [])

  const handleFieldChange = (changedValues: any, allValues: any) => {
    const filters = {
      user_id: localStorage.getItem(Define.USER_ID) || '',
      user_role: localStorage.getItem(Define.ROLE) || '',
      client_id: allValues.client?.value,
      warehouse_id: allValues.warehouse?.value,
      client_code_nom: allValues.client?.label,
      warehouse_code_nom: allValues.warehouse?.label,
      company_id: allValues.company?.value,
      company_code_nom: allValues.company?.label,
    }
    // when user change client
    if ('client' in changedValues) {
      dispatch(
        getCompanies({
          ...filters,
          company_id: undefined,
          company_code_nom: undefined,
        })
      )
      dispatch(
        getWarehouses({
          ...filters,
          warehouse_id: undefined,
          warehouse_code_nom: undefined,
        })
      )
      searchLevel(allValues)
    }
    //when user change company
    if ('company' in changedValues) {
      dispatch(
        getClients({
          ...filters,
          client_id: undefined,
          client_code_nom: undefined,
        })
      )
      dispatch(
        getWarehouses({
          ...filters,
          warehouse_code_nom: undefined,
          warehouse_id: undefined,
        })
      )
      searchLevel(allValues)
    }
    //when user change warehouse
    if ('warehouse' in changedValues) {
      dispatch(
        getClients({
          ...filters,
          client_id: undefined,
          client_code_nom: undefined,
        })
      )
      dispatch(
        getCompanies({
          ...filters,
          company_id: undefined,
          company_code_nom: undefined,
        })
      )
      searchLevel(allValues)
    }

    // when user change date state or end
    if ('start_date' in changedValues) {
      setSearchValue({
        // @ts-ignore
        startDate: dayjs(changedValues['start_date']).startOf('day'),
      })
    }

    if ('end_date' in changedValues) {
      // @ts-ignore
      setSearchValue({ endDate: dayjs(changedValues['end_date']).endOf('day') })
    }

    if ((!allValues['company'] || !allValues['warehouse']) && useForHistory) {
      const objectSearchOptionsClone = cloneDeep(objectSearchOptions).map(
        (item) => {
          if (item.value !== 'reference') item.disabled = true
          return item
        }
      )
      setObjectSearchOptions(objectSearchOptionsClone)
    } else {
      const objectSearchOptionsClone = cloneDeep(objectSearchOptions).map(
        (item) => {
          if (item.value !== 'reference') item.disabled = false
          return item
        }
      )
      setObjectSearchOptions(objectSearchOptionsClone)
    }

    if (
      'company' in allValues ||
      'client' in allValues ||
      'warehouse' in allValues
    ) {
      if (
        useForHistory &&
        allValues['company']?.label &&
        allValues['client']?.label &&
        allValues['warehouse']?.label
      ) {
        const payload = {
          client_code_nom: allValues['client'].label,
          company_code_nom: allValues['company'].label,
          warehouse_code_nom: allValues['warehouse'].label,
        }

        historyApi.getUtilisateur(payload).then((res) => {
          setUtilisateurOptions(
            (res || []).sort().map((item) => {
              return {
                label: item.name,
                value: item.id,
              }
            })
          )
        })
      }
    }
  }

  const [rangeTimestamp, setRageTimestamp] = useState({
    from: 0,
    to: 0,
  })

  const onDisabledFrom = (date: dayjs.Dayjs) => {
    const min = dayjs(MinDayForSearch).startOf('day')
    const max =
      rangeTimestamp.to === 0
        ? dayjs().endOf('day')
        : dayjs.unix(rangeTimestamp.to)
    return disabledDateBefore(min, date) || disabledDateAfter(max, date)
  }

  const onDisabledTo = (date: dayjs.Dayjs) => {
    const min =
      rangeTimestamp.from === 0
        ? dayjs(MinDayForSearch).startOf('day')
        : dayjs.unix(rangeTimestamp.from)
    const max = dayjs().endOf('day')

    return disabledDateBefore(min, date) || disabledDateAfter(max, date)
  }

  const onPickTime = (source: Source, value: dayjs.Dayjs | null) => {
    if (source === Source.FROM)
      setRageTimestamp({
        ...rangeTimestamp,
        from: value?.startOf('day').unix() || 0,
      })
    else
      setRageTimestamp({
        ...rangeTimestamp,
        to: value?.endOf('day').unix() || 0,
      })
  }

  const [objectSearchOptions, setObjectSearchOptions] = useState([
    {
      value: 'attendu',
      label: 'Attendu',
      disabled: false,
    },
    {
      value: 'commande',
      label: 'Commande',
      disabled: false,
    },
    {
      value: 'reference',
      label: 'Référence',
      disabled: false,
    },
    {
      value: 'sscc',
      label: 'SSCC',
      disabled: false,
    },
  ])

  function submitWhenEnter(e: any) {
    if (e.key === 'Enter') {
      const focusingElement = document.activeElement
      const searchBarElement =
        document.getElementsByClassName('research-bar')?.[0]
      if (searchBarElement && searchBarElement.contains(focusingElement)) {
        form.submit()
      }
    }
  }

  useEffect(() => {
    // listen to keydown event to submit form when user press Enter
    window.addEventListener('keydown', submitWhenEnter)
    return () => window.removeEventListener('keydown', submitWhenEnter)
  }, [])

  return (
    <Form
      form={form}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      onValuesChange={handleFieldChange}
      initialValues={getUrlFilterParams().condition}
    >
      <div className="research-bar relative text-xs">
        <div
          className="bg-[#EEEEEE] mx-5 px-5 py-2 flex flex-row justify-between items-center"
          style={{
            borderBottomRightRadius: isCollapsible ? 0 : 7,
            borderBottomLeftRadius: isCollapsible ? 0 : 7,
          }}
        >
          <div
            className={`grid ${useForHistory ? 'gap-x-2' : 'gap-x-4'}`}
            style={{
              gridTemplateColumns: useForHistory
                ? 'repeat(3, minmax(0, 120px)) repeat(6, minmax(0, 110px)) 70px'
                : defaultDisplayFilters
                ? 'repeat(8, minmax(0, 1fr))'
                : 'repeat(6, minmax(0, 1fr))',
            }}
          >
            <Form.Item noStyle label="" name="client" colon={false}>
              <CustomDropdown
                width="w-full"
                type="default"
                placeholder="Client"
                loading={loadingClient}
                options={clientOptions}
                className="dropdown-mandatory"
              />
            </Form.Item>
            <Form.Item noStyle label="" name="company" colon={false}>
              <CustomDropdown
                width="w-full"
                type="default"
                placeholder="Société"
                loading={loadingCompany}
                options={companyOptions}
                className="dropdown-mandatory"
              />
            </Form.Item>
            <Form.Item noStyle label="" name="warehouse" colon={false}>
              <CustomDropdown
                width="w-full"
                type="default"
                placeholder="Entrepôt"
                loading={loadingWarehouse}
                options={warehouseOptions}
                className="dropdown-mandatory"
              />
            </Form.Item>
            {useForHistory && (
              <>
                <Form.Item noStyle label="" name="chrono" colon={false}>
                  <Input
                    className="research-bar-input"
                    maxLength={10}
                    placeholder="chrono"
                    // style={{ width: 129 }}
                  />
                </Form.Item>
                <Form.Item noStyle label="" name="from" colon={false}>
                  <DatePicker
                    size="small"
                    placeholder="Date début"
                    value={
                      rangeTimestamp.from === 0
                        ? undefined
                        : dayjs.unix(rangeTimestamp.from)
                    }
                    disabledDate={onDisabledFrom}
                    onChange={(value) => onPickTime(Source.FROM, value)}
                  />
                </Form.Item>
                <Form.Item noStyle label="" name="to" colon={false}>
                  <DatePicker
                    size="small"
                    placeholder="Date fin"
                    value={
                      rangeTimestamp.to === 0
                        ? undefined
                        : dayjs.unix(rangeTimestamp.to)
                    }
                    disabledDate={onDisabledTo}
                    onChange={(value) => onPickTime(Source.TO, value)}
                  />
                </Form.Item>
                <Form.Item noStyle label="" name="user_ids" colon={false}>
                  <Select
                    allowClear
                    mode="multiple"
                    maxTagCount={'responsive'}
                    showSearch
                    // labelInValue
                    optionFilterProp="label"
                    onChange={() => {}}
                    notFoundContent={<></>}
                    options={utilisateurOptions}
                    placeholder="Utilisateur"
                  />
                </Form.Item>

                <Form.Item
                  noStyle
                  label=""
                  name="object_search_types"
                  colon={false}
                >
                  <Select
                    allowClear
                    mode="multiple"
                    showSearch={false}
                    labelInValue
                    maxTagCount="responsive"
                    filterOption={false}
                    notFoundContent={<></>}
                    options={objectSearchOptions}
                    placeholder="Elément"
                  />
                </Form.Item>

                <Form.Item noStyle label="" name="object_number" colon={false}>
                  <Input
                    className="research-bar-input"
                    maxLength={18}
                    placeholder="Numéro"
                    width="w-full"
                  />
                </Form.Item>
              </>
            )}
            {defaultDisplayFilters ? (
              <></>
            ) : (
              !useForHistory && (
                <>
                  <Form.Item noStyle label="" name="vue" colon={false}>
                    <CustomDropdown
                      width="w-full"
                      type="default"
                      placeholder="Vue standard"
                    />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    label=""
                    name={
                      screen === ModalName.STOCK
                        ? 'product_number'
                        : screen === ModalName.COMMANDE
                        ? 'nom'
                        : 'bl'
                    }
                    colon={false}
                  >
                    <Input
                      className="research-bar-input"
                      maxLength={55}
                      id={filterKey['nomInput']}
                      data-next-id={filterKey['searchBtn']}
                      placeholder={
                        screen === ModalName.STOCK
                          ? 'No Produit'
                          : screen === ModalName.COMMANDE
                          ? 'N° commande'
                          : 'No BL'
                      }
                    />
                  </Form.Item>
                </>
              )
            )}

            <div className="flex flex-row gap-x-2">
              <img
                tabIndex={0}
                id={filterKey['searchBtn']}
                data-previous-id={filterKey['nomInput']}
                data-next-id={filterKey['resetBtn']}
                src={SEARCH_ICON}
                alt="masques"
                className="cursor-pointer"
                onClick={submitForm}
              />

              <img
                src={CLOSE_ICON}
                alt="masques"
                tabIndex={0}
                id={filterKey['resetBtn']}
                data-previous-id={filterKey['searchBtn']}
                className={
                  isOnlyOneOption ? 'bg-gray3 rounded-md' : 'cursor-pointer'
                }
                onClick={() => !isOnlyOneOption && resetFilter()}
              />
            </div>
          </div>
          <div
            className={`flex flex-row ${
              useForHistory ? '' : 'gap-x-4 w-56'
            }  items-center  justify-end gap-x-2	`}
          >
            {total ? (
              <p className="text-[#808080]">
                <span className="font-bold">
                  {`${t('result')}${Number(totalIn) > 1 ? 's' : ''}`}:
                </span>
                {` ${totalIn || '0'} ${t('in')} ${total || ''}`}
              </p>
            ) : null}
            {listFilters && (
              <div onClick={handleCollapsible} className="">
                {isCollapsible ? (
                  <img src={ARROW_UP} alt="up" />
                ) : (
                  <img src={ARROW_DOWN} alt="down" />
                )}
              </div>
            )}
          </div>
        </div>
        <ResearchContext.Provider value={searchValue}>
          {listFilters && (
            <div
              className={
                isCollapsible ? 'research-bar-expand' : 'research-bar-collapse'
              }
            >
              <ResearchBarExpand
                listFilters={listFilters}
                screen={screen}
                useForHistory={useForHistory}
              />
            </div>
          )}
        </ResearchContext.Provider>
      </div>
    </Form>
  )
}

export default ResearchBar
